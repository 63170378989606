<template lang="pug">
div
  h2 Tags
  figure.highlighted
    .d-flex.mb-3
      om-tags(v-model="selectedTags" :items="tags")
    .d-flex.w-100.row.mx-0.mt-5
      .col-12
        h2.mb-4.ml-2 Usage
      .col-12
      .col-12
        .brand-use-code
          highlight.language-markup(:codeStr="code")
</template>

<script>
  import base from './base';

  export default {
    mixins: [base],

    data() {
      return {
        selectedTags: [{ _id: '1', name: 'test1' }],
        tags: [
          { _id: '1', name: 'test1' },
          { _id: '2', name: 'test2' },
          { _id: '3', name: 'test3' },
        ],
      };
    },

    computed: {
      code() {
        return `om-tags(v-model="selectedTags" :items="tags")`;
      },
    },
  };
</script>
